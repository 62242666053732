





























































































import VueBase from '@/VueBase'
import { Component } from 'vue-property-decorator'
@Component({
  name: 'StatusMonitoring',
})
export default class StatusMonitoring extends VueBase {
  private logo = '/upload/assets/img/logo.png?v=' + String(Math.random())
  private logo_en = '/upload/assets/img/logo_en.png?v=' + String(Math.random())
  private now_version = '3.0.0' //'2.7.0'//'2.6.1'
  private new_version = ''
  private userToken = ''
  private versionVisible = false
  private versionMap: any = {}
  private versionString = ''
  private async getVersion() {
    // 由于我们直接设置了 now_version 的值，这个方法可以保持空的，或者你可以选择不调用这个方法
  }
  private async getNowVersion() {
    // const res = await this.services.setting.nowVersion()
    // const versionArr = res.data.split('\n')
    // versionArr.pop()
    // versionArr.forEach((item: any) => {
    //   const itemArr = item.split(',')
    //   const name = itemArr[0]
    //   const key = itemArr[1]
    //   const value = itemArr[2]
    //   if (this.versionMap[name]) {
    //     this.versionMap[name][key] = value
    //   } else {
    //     this.versionMap[name] = {
    //       [key]: value,
    //     }
    //   }
    // })

    const res2 = await this.services.setting.versionlist()
    if (res2.status === 201) {
      this.versionMap = { ...res2.data, ...this.versionMap }
    }
    this.now_version = this.versionMap.DongTai.version
    this.versionString = JSON.stringify(this.versionMap)
  }
  private onError() {
    this.$message.error('版本信息复制失败，请再次尝试')
  }
  private onCopy() {
    this.$message.success('版本信息复制成功')
  }
  private onErrorToken() {
    this.$message.error('token 信息复制失败，请再次尝试')
  }
  private onCopyToken() {
    this.$message.success('token 信息复制成功')
  }
  private toUpdate() {
    window.open(`https://docs.microview.io/docs/changelog/release-note`)
  }
  private toHelp() {
    window.open('https://doc.microview.io/')
  }

  private toBug() {
    window.open('https://github.com/microview/issues')
  }
  private toApi() {
    window.open('https://i0x0fy4ibf.feishu.cn/docx/doxcnSoxZjm2nEMyT3KJwg6ej4e')
  }
  private async getUserToken() {
    const res = await this.services.user.userToken()
    if (res.status === 201) {
      this.userToken = res.data.token
    }
  }
  created() {
    //this.getNowVersion()  // 不再需要调用这个方法
    this.getUserToken()
    this.getVersion()
  }
}
